var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-1-box"},[_vm._m(0),_c('div',{staticClass:"active-1-info-box"},[_c('el-descriptions',{attrs:{"title":"","column":3}},[_c('el-descriptions-item',{attrs:{"label":"行驶里程"}},[_vm._v(_vm._s(_vm.info.drivingMileage || "--")+" 公里")]),_c('el-descriptions-item',{attrs:{"label":"使用油耗"}},[_vm._v(_vm._s(_vm.info.fuelConsumption || "--")+" 升")]),_c('el-descriptions-item',{attrs:{"label":"平均百公里油耗"}},[_vm._v(_vm._s(_vm.info.avgFuel || "--")+" 升")]),_c('el-descriptions-item',{attrs:{"label":"使用电耗"}},[_vm._v(_vm._s(_vm.info.powerConsumption || "--")+" 度")]),_c('el-descriptions-item',{attrs:{"label":"平均百公里电耗"}},[_vm._v(_vm._s(_vm.info.avgPower || "--")+"度")])],1)],1),_vm._m(1),_c('div',{staticClass:"active-1-table-box"},[_c('vxe-table',{ref:"vxeTable",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"300","data":_vm.records,"sort-config":{ sortMethod: _vm.customSortMethod }}},[_c('vxe-table-column',{attrs:{"title":"零件名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.partsStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.count)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"更换日期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.replaceTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"下一次更换日期","sortable":"","field":"nextReplaceTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.nextReplaceTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.remarks)+" ")]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.ajaxParam.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.ajaxParam.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"size-change":function () {
                    _vm.queryList();
                },"current-change":function () {
                    _vm.queryList();
                }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("运行情况")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("重要零件更换记录")])])}]

export { render, staticRenderFns }