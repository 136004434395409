<template>
    <div class="active-2-box">
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">总体情况</div>
        </div>
        <div class="active-2-info-box">
            <el-descriptions title="" :column="4">
                <el-descriptions-item label="机动车商业保险保障金额">
                    {{ info.guaranteeAmountA || "--" }}元
                </el-descriptions-item>
                <el-descriptions-item label="参保次数">
                    {{ info.numA || "--" }}次
                </el-descriptions-item>
                <el-descriptions-item label="保费总金额">
                    {{ info.premiumAmountA || "--" }}元
                </el-descriptions-item>
                <el-descriptions-item label="保单状态">
                    {{ info.statuA || "--" }}
                </el-descriptions-item>

                <el-descriptions-item
                    label="机动车交通事故责任强制保险保障金额"
                >
                    {{ info.guaranteeAmountB || "--" }}元
                </el-descriptions-item>
                <el-descriptions-item label="参保次数">
                    {{ info.numB || "--" }}次
                </el-descriptions-item>
                <el-descriptions-item label="保费总金额">
                    {{ info.premiumAmountB || "--" }}元
                </el-descriptions-item>
                <el-descriptions-item label="保单状态">
                    {{ info.statuB || "--" }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">保单记录</div>
        </div>
        <div class="active-2-table-box">
            <vxe-table
                ref="vxeTable"
                border
                resizable
                show-overflow
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyleFn"
                align="center"
                class="vxe-table-element"
                height="300"
                :data="records"
                :sort-config="{ sortMethod: customSortMethod }"
            >
                <vxe-table-column title="保单名称">
                    <template v-slot="{ row }">
                        {{ row.policyTypeStr }}
                    </template>
                </vxe-table-column>

                <vxe-table-column title="保单号">
                    <template v-slot="{ row }">
                        {{ row.policyNum }}
                    </template>
                </vxe-table-column>

                <vxe-table-column title="保障金额">
                    <template v-slot="{ row }">
                        ￥{{ row.guaranteeAmount }}
                    </template>
                </vxe-table-column>

                <vxe-table-column title="保费金额">
                    <template v-slot="{ row }">
                        ￥{{ row.premiumAmount }}
                    </template>
                </vxe-table-column>

                <vxe-table-column title="保险期间">
                    <template v-slot="{ row }">
                        {{ row.takeEffectTime }} ~ {{ row.invalidTime }}
                    </template>
                </vxe-table-column>

                <vxe-table-column title="状态">
                    <template v-slot="{ row }">
                        {{ row.status }}
                    </template>
                </vxe-table-column>
            </vxe-table>
            <div style="height: 20px"></div>
            <el-pagination
                background
                :current-page.sync="ajaxParam.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size.sync="ajaxParam.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="
                    () => {
                        queryList();
                    }
                "
                @current-change="
                    () => {
                        queryList();
                    }
                "
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: "",
        },
        op: {
            type: Object,
            default: () => {
                return {
                    row: null,
                    id: "",
                };
            },
        },
        companyId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: "",
                // direction: "ASC", //排序方式，默认降序 DESC, ASC
                // property: "nextReplaceTime", // 排序字段，默认创建时间
            },
            info: {
                guaranteeAmountA: 0,
                guaranteeAmountB: 0,
                numA: 0,
                numB: 0,
                premiumAmountA: 0,
                premiumAmountB: 0,
                statuA: 0,
                statuB: 0,
            },
            records: [],
            total: 0,
        };
    },
    created() {
        // this.queryList();
    },
    methods: {
        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.ajaxParam.direction == order.toUpperCase()) {
                return null;
            }
            this.ajaxParam.direction = order == "asc" ? "ASC" : "DESC";
            this.queryList();
        },
        async queryList(startDate, endDate) {
            // const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            // delete ajaxParam.companyId;
            {
                const ajaxParam = {
                    currentPage: this.ajaxParam.currentPage,
                    pageSize: this.ajaxParam.pageSize,
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carPolicy/statistic/list/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    this.records = data.content;
                    this.total = ~~data.totalElements;
                }
            }
            {
                const ajaxParam = {
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carPolicy/statistic/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    Object.keys(this.info).forEach((key) => {
                        this.info[key] = data[key];
                    });
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active-2-box {
    border: 1px solid #e9e9e9;
    padding: 32px;
    border-radius: 6px;
    .title-box {
        // height: 50px;
        display: flex;
        align-items: center;

        .title-icon {
            width: 5px;
            height: 16px;
            background: rgb(72, 152, 226);
            margin-right: 10px;
        }
    }

    .active-2-info-box {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 16px;
        .info-item {
            width: 300px;
            margin: 8px 0;
        }
    }
    .active-2-table-box {
        padding: 16px 0;
    }
}
</style>
