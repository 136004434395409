<template>
    <div class="carRecordManage-LookDetails">
        <div class="carRecordManage-LookDetails-close">
            <div>车辆技术档案详情</div>
            <i
                class="el-icon-close"
                @click="
                    () => {
                        $emit('close');
                    }
                "
            ></i>
        </div>
        <div class="carRecordManage-LookDetails-top">
            <div>{{ info.carNum }}</div>

            <div class="carRecordManage-LookDetails-top-right">
                <GlobalElDatePicker
                    @change="lookfor"
                    v-model="daterange"
                    type="daterange"
                    size="small"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                />
                <el-button type="primary" size="small" @click="onDownload"
                    >导出档案</el-button
                >
            </div>
        </div>

        <el-descriptions title="" :column="6">
            <el-descriptions-item label="车辆自编号">
                {{ info.carNo }}
            </el-descriptions-item>
            <el-descriptions-item label="车型">
                {{ info.carModelName }}
            </el-descriptions-item>
            <el-descriptions-item label="生产厂商">
                {{ info.manufacturer }}
            </el-descriptions-item>
            <el-descriptions-item label="技术性能">
                {{ info.technologyNature }}
            </el-descriptions-item>
            <el-descriptions-item label="车辆行驶线路">
                {{ info.lineName }}
            </el-descriptions-item>
            <el-descriptions-item label="车辆状态">
                {{ info.statuStr }}
            </el-descriptions-item>

            <el-descriptions-item label="购入日期">
                {{ info.purchaseTime }}
            </el-descriptions-item>
            <el-descriptions-item label="报废年限">
                {{ info.scrapTime }}
            </el-descriptions-item>
            <el-descriptions-item label="所属公司">
                {{ info.companyName }}
            </el-descriptions-item>
            <el-descriptions-item label="上次年检时间">
                {{ info.lastTimeAsTime }}
            </el-descriptions-item>
            <el-descriptions-item label="下次年检时间">
                {{ info.nextTimeAsTime }}
            </el-descriptions-item>
            <el-descriptions-item label="距离下次年检还剩">
                <!-- {{ info.daysRemain }} -->
                <div v-if="typeof info.daysRemain == 'number'">
                    <div
                        :style="
                            info.daysRemain < 0
                                ? `color: #DB1A1A`
                                : `color: #33CCB0`
                        "
                    >
                        {{ info.daysRemain }}天
                    </div>
                </div>
            </el-descriptions-item>
        </el-descriptions>

        <el-tabs v-model="activeName" type="card" style="margin-top: 16px">
            <el-tab-pane label="运行使用情况" name="1">
                <Active1
                    :op="op"
                    :id="id"
                    :companyId="companyId"
                    ref="Active1"
                />
            </el-tab-pane>
            <el-tab-pane label="保单情况" name="2">
                <Active2
                    :op="op"
                    :id="id"
                    :companyId="companyId"
                    ref="Active2"
                />
            </el-tab-pane>
            <el-tab-pane label="车辆维修记录" name="3">
                <Active3
                    :op="op"
                    :id="id"
                    :companyId="companyId"
                    ref="Active3"
                />
            </el-tab-pane>
            <el-tab-pane label="事故处理情况" name="4">
                <Active4
                    :op="op"
                    :id="id"
                    :companyId="companyId"
                    ref="Active4"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Active1 from "./Active1.vue";
import Active2 from "./Active2.vue";
import Active3 from "./Active3.vue";
import Active4 from "./Active4.vue";
import { bytesToFile } from "@/utils/handleByte";
export default {
    components: {
        Active1,
        Active2,
        Active3,
        Active4,
    },
    name: "LookDetails",
    props: {
        id: {
            type: String,
            default: "",
        },
        companyId: {
            type: String,
            default: "",
        },
        op: {
            type: Object,
            default: () => {
                return {
                    row: null,
                    id: "",
                };
            },
        },
    },
    data() {
        return {
            activeName: "1",
            daterange: [],

            info: {},
        };
    },
    created() {
        this.lookfor();
    },
    methods: {
        async lookfor() {
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carManage/${this.id}`
            );
            if (code == 200) {
                this.$nextTick(() => {
                    this.queryList();
                });

                this.info = data;
            }
        },
        queryList() {
            if (Array.isArray(this.daterange) && this.daterange.length > 0) {
                this.$refs.Active1.queryList(
                    this.daterange[0],
                    this.daterange[1]
                );
                this.$refs.Active2.queryList(
                    this.daterange[0],
                    this.daterange[1]
                );
                this.$refs.Active3.queryList(
                    this.daterange[0],
                    this.daterange[1]
                );
                this.$refs.Active4.queryList(
                    this.daterange[0],
                    this.daterange[1]
                );
            } else {
                this.$refs.Active1.queryList();
                this.$refs.Active2.queryList();
                this.$refs.Active3.queryList();
                this.$refs.Active4.queryList();
            }
        },

        async onDownload() {
            if (this.daterange.length < 2) {
                this.$message({
                    message: "请选择开始时间和结束时间",
                    type: "warning",
                    center: true,
                });

                return;
            }
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/CarInspection/statistic/export/${this.id}`,
                {
                    params: {
                        companyId: this.companyId,
                        startDate: this.daterange[0],
                        endDate: this.daterange[1],
                    },
                }
            );
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.carRecordManage-LookDetails {
    display: flex;
    flex-direction: column;
    .carRecordManage-LookDetails-close {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .el-icon-circle-close {
            font-size: 30px;
            cursor: pointer;
        }
    }
    .carRecordManage-LookDetails-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .carRecordManage-LookDetails-top-right {
            .el-button {
                margin-left: 10px;
            }
        }
    }
}
</style>
