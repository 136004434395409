<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
        center
        :title="info.title"
    >
        <div v-if="!userShowList">
            <!-- <div slot="title" class="header-title">
                <el-row :gutter="5">
                    <el-col :span="24">
                        <span class="title-name" style="font-size: 20px">{{
                            info.title
                        }}</span>
                    </el-col>
                </el-row>
            </div> -->
            <div style="width: 100%; height: 20px"></div>
            <el-row :gutter="0">
                <el-col :span="24">
                    <el-form ref="ruleForm" label-width="110px" class="allForm">
                        <el-form-item label="车牌号" :required="true">
                            <el-input v-model="form.carNum" class="formInput" />
                        </el-form-item>
                        <el-form-item
                            v-if="$admin"
                            label="公司"
                            :required="true"
                        >
                            <el-select
                                v-model="form.companyId"
                                class="formInput w-100"
                                filterable
                                @change="changeCompany"
                            >
                                <el-option
                                    v-for="(v, i) in company"
                                    :key="i"
                                    :value="v.id"
                                    :label="v.companyName"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-if="industry == 4"
                            label="线路"
                            :required="true"
                        >
                            <el-select
                                v-model="form.lineId"
                                class="formInput w-100"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(v, i) in lineList"
                                    :key="i"
                                    :value="v.id"
                                    :label="v.lineName"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车型">
                            <el-select
                                v-model="form.carModels"
                                class="formInput w-100"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(v, i) in carModel"
                                    :key="i"
                                    :value="v.dictCode"
                                    :label="v.dictName"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车辆自编号">
                            <el-input v-model="form.carNo" class="formInput" />
                        </el-form-item>
                        <!-- <el-form-item label="人员" :required="true">
							<el-button type="primary" @click="clickUserShow">选择人员</el-button>
						</el-form-item> -->
                        <el-form-item
                            label="生产厂商"
                            class="formInput"
                            :required="true"
                        >
                            <el-input
                                v-model="form.manufacturer"
                                class="formInput"
                            />
                        </el-form-item>
                        <el-form-item
                            label="技术性能"
                            class="formInput"
                            :required="true"
                        >
                            <el-input
                                v-model="form.technologyNature"
                                class="formInput"
                            />
                        </el-form-item>
                        <el-form-item
                            label="报废年限"
                            class="formInput"
                            :required="true"
                        >
                            <el-date-picker
                                v-model="form.scrapTime"
                                class="formInput"
                                clearableel-date-editor
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            />
                        </el-form-item>
                        <el-form-item label="购置时间" class="formInput">
                            <el-date-picker
                                v-model="form.purchaseTime"
                                class="formInput"
                                clearableel-date-editor
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            />
                        </el-form-item>
                        <el-form-item label="上次年检时间" class="formInput">
                            <el-date-picker
                                v-model="form.lastTimeAsTime"
                                type="date"
                                class="formInput"
                                clearable
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            />
                        </el-form-item>
                        <el-form-item label="下次年检时间" class="formInput">
                            <el-date-picker
                                v-model="form.nextTimeAsTime"
                                type="date"
                                class="formInput"
                                clearable
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            />
                        </el-form-item>
                        <el-form-item label="设备ID">
                            <el-select
                                v-model="form.deviceId"
                                class="formInput w-100"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(v, i) in deviceList"
                                    :key="i"
                                    :value="v.id"
                                    :label="v.deviceId"
                                />
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="commit">提交</el-button>
                <el-button type="info" @click="close">取消</el-button>
            </div>
        </div>
        <div v-if="userShowList">
            <div class="flex-row-box flex-cont-rl pb-20">
                <div>
                    <el-checkbox
                        v-model="checkAll"
                        @change="handleCheckAllChange"
                        >全选</el-checkbox
                    >
                </div>
                <el-input
                    placeholder="请输入内容"
                    v-model="searchName"
                    class="input-with-select"
                >
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="searchNameClick"
                    ></el-button>
                </el-input>
            </div>
            <div style="width: 100%; height: 500px; overflow-y: scroll">
                <el-row :gutter="0">
                    <el-col :span="24">
                        <el-checkbox-group
                            v-model="form.carUsers"
                            @change="handleCheckedCitiesChange"
                        >
                            <el-checkbox
                                v-for="(v, i) in user"
                                :label="v.id"
                                :key="i"
                                :value="v.id"
                                style="width: 150px; height: 30px"
                                >{{ v.realName }}</el-checkbox
                            >
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </div>
            <el-pagination
                background
                :current-page="currentPage"
                :page-sizes="[5, 10, 15, 20, 48, 100, 150, 200, 1000, 2000]"
                :page-size="pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
            <div
                slot="footer"
                class="dialog-footer"
                style="text-align: center; padding-top: 20px"
            >
                <el-button type="primary" @click="clickUser"
                    >确定人员</el-button
                >
                <el-button type="info" @click="clickUser">取消人员</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { textIdCard, textTel, textCar } from "@/utils/validated";
import {
    carManage,
    company,
    getDictParentCode,
    user,
    carManageV21,
} from "@/api";
export default {
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                carNum: "",
                companyId: this.$admin ? "" : this.$companyId,
                // carUsers: [],
                lastTimeAsTime: null,
                carModels: "",
                purchaseTime: null,
                nextTimeAsTime: null,
                lineId: "",
                carNo: "",
                deviceId: "",
                manufacturer: "",
                technologyNature: "",
                scrapTime: "",
            },
            company: [],
            user: [],
            carModel: [],
            driver: [
                {
                    name: "",
                },
            ],
            checkAll: false,
            searchName: "",
            userShowList: false,
            currentPage: 1,
            pageSize: 48,
            total: 0,
            lineList: [],
            deviceList: [],
            industry: "",
        };
    },
    created() {
        this.getCompany();
        this.getCarModel();
    },
    mounted() {
        if (!this.$admin) {
            this.getUserByCom();
            this.industry = JSON.parse(
                sessionStorage.UserDetail
            ).sysCompanyVo.industry;
        }
        this.upd();
    },
    methods: {
        handleCheckedCitiesChange() {
            if (this.form.carUsers.length === this.user.length) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        },
        clickUserShow() {
            if (!this.form.companyId) {
                this.$message.error("请选择公司");
                return false;
            }
            this.userShowList = true;
            if (this.form.carUsers.length === this.user.length) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        },
        clickUser() {
            this.userShowList = false;
        },
        handleCheckAllChange(val) {
            this.form.carUsers = [];
            if (val) {
                for (let i = 0; i < this.user.length; i++) {
                    this.form.carUsers.push(this.user[i].id);
                }
            }
        },
        upd() {
            if (this.info.addOr === "upd") {
                this.form.lineId = this.row.lineId;
                this.form.carNum = this.row.carNum;
                this.form.lastTimeAsTime = this.row.lastTimeAsTime;
                this.form.purchaseTime = this.row.purchaseTime;
                this.form.nextTimeAsTime = this.row.nextTimeAsTime;
                this.form.companyId = this.row.companyId;
                this.form.carNo = this.row.carNo;
                (this.form.carModels = this.row.carModels
                    ? this.row.carModels + ""
                    : ""),
                    // console.log(JSON.stringify(this.row))
                    (this.form.carUsers = []);
                this.form.userMsg = null;
                this.form.manufacturer = this.row.manufacturer;
                this.form.technologyNature = this.row.technologyNature;
                this.form.scrapTime = this.row.scrapTime;

                if (this.row.carModels === 0) {
                    this.form.carModels = "0";
                }
                this.getcarManage(this.row.id);
                this.getUserByCom();
                this.getDevice();
            } else {
                if (!this.$admin) {
                    this.getDevice();
                }
            }
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.company = res.data.content;
            });
        },
        getcarManage(id) {
            this.$axiosReq(
                "/car/server/escort/web/carManage/" + id,
                null,
                null,
                "get"
            ).then((res) => {
                this.form.deviceId = res.data.deviceId;
                this.industry = res.data.industry;
                for (const i of res.data.userMsg) {
                    this.form.carUsers.push(i.userId);
                }
            });
        },
        getDevice() {
            this.$axiosReq(
                "/car/server/escort/web/jt808/company/" + this.form.companyId,
                null,
                null,
                "get"
            ).then((res) => {
                this.deviceList = res.data;
            });
        },
        getUserByCom() {
            let that_ = this;
            this.$axiosReq(
                "/car/server/escort/web/line/companyId",
                null,
                {
                    companyId: this.$admin
                        ? this.form.companyId
                        : this.$companyId,
                },
                "get"
            ).then((res) => {
                this.lineList = res.data;
            });
        },
        changeCompany(v) {
            let that_ = this;
            this.form.lineId = "";
            this.form.deviceId = "";
            this.getDevice();
            this.getUserByCom();
            this.company.forEach((item) => {
                if (v == item.id) {
                    that_.industry = item.industry;
                }
            });
        },
        searchNameClick() {
            this.getUserByCom();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.currentPage = 1;
            this.getUserByCom();
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getUserByCom();
        },
        getCarModel() {
            this.$axiosReq(
                getDictParentCode + "MOTORCYCLE_TYPE",
                null,
                null,
                "get"
            ).then((res) => {
                this.carModel = res.data;
            });
        },
        // 提交添加
        commit() {
            if (this.$textNull(this.form.carNum)) {
                this.$message.error("请填写车牌号！");
                return;
            } else if (textCar(this.form.carNum)) {
                this.$message.error("请填写正确车牌号！");
                return;
            } else if (this.$textNull(this.form.companyId)) {
                this.$message.error("请选择企业！");
                return;
            } else if (this.$textNull(this.form.manufacturer)) {
                this.$message.error("请选择生产厂商！");
                return;
            } else if (this.$textNull(this.form.technologyNature)) {
                this.$message.error("请选择技术性能！");
                return;
            } else if (this.$textNull(this.form.scrapTime)) {
                this.$message.error("请选择报废年限！");
                return;
            }
            if (
                this.form.carModels ||
                this.form.carModels === 0 ||
                this.form.carModels === "0"
            ) {
                this.form.carModels = Number(this.form.carModels);
            }
            if (this.info.addOr === "add") {
                this.$axiosReq(
                    "/car/server/escort/web/carManage",
                    this.form,
                    null,
                    "post"
                )
                    .then((res) => {
                        this.close();
                        this.$emit("get-list");
                        this.$message.success(res.msg);
                    })
                    .catch((err) => {
                        this.form.carModels = this.form.carModels + "";
                    });
            } else {
                this.$axiosReq(
                    "/car/server/escort/web/carManage/" + this.row.id,
                    this.form,
                    null,
                    "put"
                ).then((res) => {
                    this.close();
                    this.$emit("get-list");
                    this.$message.success(res.msg);
                });
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.input-with-select {
    width: 280px;
}

.pb-20 {
    padding-bottom: 20px;
}
</style>
