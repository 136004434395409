var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-3-box"},[_vm._m(0),_c('div',{staticClass:"active-3-info-box"},[_c('el-descriptions',{attrs:{"title":"","column":4}},[_c('el-descriptions-item',{attrs:{"label":"维修总次数"}},[_vm._v(_vm._s(_vm.info.allNum || "--")+"次")]),_c('el-descriptions-item',{attrs:{"label":"维修总费用"}},[_vm._v(_vm._s(_vm.info.allCosts || "--")+"元")]),_c('el-descriptions-item',{attrs:{"label":"车辆维修次数"}},[_vm._v(_vm._s(_vm.info.allNumA || "--")+"次")]),_c('el-descriptions-item',{attrs:{"label":"车辆维修费用"}},[_vm._v(_vm._s(_vm.info.allCostsA || "--")+"元")]),_c('el-descriptions-item',{attrs:{"label":"小修次数"}},[_vm._v(_vm._s(_vm.info.allNumB || "--")+"次")]),_c('el-descriptions-item',{attrs:{"label":"小修费用"}},[_vm._v(_vm._s(_vm.info.allCostsB || "--")+"元")]),_c('el-descriptions-item',{attrs:{"label":"大修次数"}},[_vm._v(_vm._s(_vm.info.allNumC || "--")+"次")]),_c('el-descriptions-item',{attrs:{"label":"大修费用"}},[_vm._v(_vm._s(_vm.info.allCostsC || "--")+"元")])],1)],1),_vm._m(1),_c('div',{staticClass:"active-3-table-box"},[_c('vxe-table',{ref:"vxeTable",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"300","data":_vm.records,"sort-config":{ sortMethod: _vm.customSortMethod }}},[_c('vxe-table-column',{attrs:{"title":"维护日期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.arrivalTime)+"~"+_vm._s(row.deliveryTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"里程表示值（km）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.drivingMileage)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"维修类别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maintenanceTypeStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"维护项目"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maintenanceProjectStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"维护费用(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥"+_vm._s(row.maintenanceCosts)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"维护单位","show-header-overflow":"","show-overflow":"title","show-footer-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maintenanceUnit)+" ")]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.ajaxParam.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.ajaxParam.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"size-change":function () {
                    _vm.queryList();
                },"current-change":function () {
                    _vm.queryList();
                }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("总体情况")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("维修记录")])])}]

export { render, staticRenderFns }