<template>
    <div class="active-1-box">
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">运行情况</div>
        </div>
        <div class="active-1-info-box">
            <el-descriptions title="" :column="3">
                <el-descriptions-item label="行驶里程"
                    >{{
                        info.drivingMileage || "--"
                    }}
                    公里</el-descriptions-item
                >
                <el-descriptions-item label="使用油耗"
                    >{{ info.fuelConsumption || "--" }} 升</el-descriptions-item
                >
                <el-descriptions-item label="平均百公里油耗"
                    >{{ info.avgFuel || "--" }} 升</el-descriptions-item
                >
                <el-descriptions-item label="使用电耗"
                    >{{
                        info.powerConsumption || "--"
                    }}
                    度</el-descriptions-item
                >
                <el-descriptions-item label="平均百公里电耗"
                    >{{ info.avgPower || "--" }}度</el-descriptions-item
                >
            </el-descriptions>
        </div>
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">重要零件更换记录</div>
        </div>
        <div class="active-1-table-box">
            <vxe-table
                ref="vxeTable"
                border
                resizable
                show-overflow
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyleFn"
                align="center"
                class="vxe-table-element"
                height="300"
                :data="records"
                :sort-config="{ sortMethod: customSortMethod }"
            >
                <vxe-table-column title="零件名称">
                    <template v-slot="{ row }">
                        {{ row.partsStr }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="数量">
                    <template v-slot="{ row }">
                        {{ row.count }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="更换日期">
                    <template v-slot="{ row }">
                        {{ row.replaceTime }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="下一次更换日期"
                    sortable
                    field="nextReplaceTime"
                >
                    <template v-slot="{ row }">
                        {{ row.nextReplaceTime }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="备注">
                    <template v-slot="{ row }">
                        {{ row.remarks }}
                    </template>
                </vxe-table-column>
            </vxe-table>
            <div style="height: 20px"></div>
            <el-pagination
                background
                :current-page.sync="ajaxParam.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size.sync="ajaxParam.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="
                    () => {
                        queryList();
                    }
                "
                @current-change="
                    () => {
                        queryList();
                    }
                "
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: "",
        },
        op: {
            type: Object,
            default: () => {
                return {
                    row: null,
                    id: "",
                };
            },
        },
        companyId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: "",
                // direction: "ASC", //排序方式，默认降序 DESC, ASC
                // property: "nextReplaceTime", // 排序字段，默认创建时间
            },
            info: {
                avgPower: 0,
                avgFuel: 0,
                drivingMileage: 0,
                fuelConsumption: 0,
                powerConsumption: 0,
            },
            records: [],
            total: 0,
        };
    },
    created() {
        // this.queryList();
    },
    methods: {
        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.ajaxParam.direction == order.toUpperCase()) {
                return null;
            }
            this.ajaxParam.direction = order == "asc" ? "ASC" : "DESC";
            this.queryList();
        },
        async queryList(startDate, endDate) {
            // const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            // delete ajaxParam.companyId;
            {
                const ajaxParam = {
                    currentPage: this.ajaxParam.currentPage,
                    pageSize: this.ajaxParam.pageSize,
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carPartsReplaceRecord/statistic/list/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    this.records = data.content;
                    this.total = ~~data.totalElements;
                }
            }
            {
                const ajaxParam = {
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carEnergyConsumption/statistic/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    Object.keys(this.info).forEach((key) => {
                        this.info[key] = data[key];
                    });
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active-1-box {
    border: 1px solid #e9e9e9;
    padding: 32px;
    border-radius: 6px;
    .title-box {
        // height: 50px;
        display: flex;
        align-items: center;

        .title-icon {
            width: 5px;
            height: 16px;
            background: rgb(72, 152, 226);
            margin-right: 10px;
        }
    }

    .active-1-info-box {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 16px;
        .info-item {
            width: 300px;
            margin: 8px 0;
        }
    }
    .active-1-table-box {
        padding: 16px 0;
    }
}
</style>
