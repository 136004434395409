<template>
    <div>
        <div v-if="!showLookDetails" class="dashboard-container">
            <Operate
                v-if="dialog.refresh"
                :dialog.sync="dialog.show"
                :row="dialog.row"
                :info="dialog.info"
                @get-list="getList"
                @refresh="refreshDialog"
            />
            <BatchExport
                v-if="dialog.refresh2"
                :dialog.sync="dialog.show2"
                @get-list="getList"
                @refresh="refreshDialog"
            />
            <User
                v-if="dialog.refreshUser"
                :dialog.sync="dialog.showUser"
                :row="dialog.row"
                :info="dialog.info"
                @get-list="getList"
                @refresh="refreshDialog"
            />
            <InspectDetail
                v-if="dialog.refreshIns"
                :dialog.sync="dialog.showIns"
                :row="dialog.row"
                :info="dialog.info"
                @get-list="getList"
                @refresh="refreshDialog"
            />
            <el-card>
                <el-row type="flex" justify="start" :gutter="0">
                    <el-col :span="4">
                        <span class="text-primary text-pad-right">车牌号</span>
                        <el-input
                            v-model="condition.carNum"
                            size="mini"
                            maxlength="20"
                            style="width: 200px"
                            class="input searchInput"
                            placeholder="请输入车牌号"
                            @input="changeCondition('2')"
                        />
                    </el-col>
                    <el-col :span="5">
                        <span class="text-primary text-pad-right"
                            >车辆自编号</span
                        >
                        <el-input
                            maxlength="20"
                            v-model="condition.carNo"
                            size="mini"
                            style="width: 200px"
                            class="input searchInput"
                            placeholder="请输入车辆自编号"
                            @input="changeCondition('2')"
                        />
                    </el-col>
                    <el-col :span="4">
                        <span class="text-primary text-pad-right"
                            >公司名称</span
                        >
                        <el-select
                            v-model="companyId"
                            filterable
                            size="mini"
                            class="input searchInput"
                            @change="changeCondition('1')"
                        >
                            <el-option
                                v-for="(item, index) in company"
                                :key="index"
                                :value="item.id"
                                :label="item.companyName"
                            />
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <span class="text-primary text-pad-right">线路</span>
                        <!-- <el-input v-model="condition.lineId" style="width: 200px" size="mini" class="input searchInput" @input="changeCondition" /> -->
                        <el-select
                            v-model="condition.lineId"
                            clearable
                            filterable
                            size="mini"
                            class="input searchInput"
                            @change="changeCondition('3')"
                        >
                            <el-option
                                v-for="(item, index) in lineList"
                                :key="index"
                                :value="item.id"
                                :label="item.lineName"
                            />
                        </el-select>
                    </el-col>
                    <el-col :span="7">
                        <span class="text-primary text-pad-right">车型</span>
                        <GlobalDictSelect
                            size="mini"
                            parentCode="MOTORCYCLE_TYPE"
                            v-model="condition.carModels"
                            @change="getList"
                        >
                        </GlobalDictSelect>
                        <!-- <span class="text-primary text-pad-right">年检时间</span>
                    <el-date-picker
                        v-model="condition.date"
                        type="daterange"
                        class="input searchInput"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        size="mini"
                        @change="changeCondition"
                    /> -->
                    </el-col>
                </el-row>
            </el-card>
            <el-card style="margin-top: 20px">
                <el-row>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="space-between">
                                <el-col>
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:car:add'
                                            )
                                        "
                                        type="primary"
                                        size="mini"
                                        @click="
                                            openDialog({
                                                title: '添加车辆',
                                                addOr: 'add',
                                            })
                                        "
                                        >添加车辆</el-button
                                    >
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:car:batch:import'
                                            )
                                        "
                                        type="warning"
                                        size="mini"
                                        @click="
                                            openExport({
                                                title: '批量导入',
                                                addOr: 'batch',
                                            })
                                        "
                                        >批量导入</el-button
                                    >
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:car:batch:delete'
                                            )
                                        "
                                        type="danger"
                                        size="mini"
                                        @click="removeAll({ del: 'batch' })"
                                        >批量删除</el-button
                                    >
                                </el-col>
                                <el-col :span="1">
                                    <vxe-button @click="getList"
                                        >刷新</vxe-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>
                    <vxe-table
                        ref="table"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyle"
                        align="center"
                        class="vxe-table-element"
                        height="580"
                        row-id="company_id"
                        :sort-config="{ sortMethod: customSortMethod }"
                        :filter-config="{ remote: true }"
                        :checkbox-config="{ reserve: true }"
                        :data="table.list"
                        :loading="loading"
                    >
                        <vxe-table-column
                            type="checkbox"
                            width="60"
                            fixed="left"
                        />
                        <!-- <vxe-table-column type="seq" width="60" /> -->
                        <vxe-table-column field="carNum" title="车牌号" />
                        <vxe-table-column field="carNo" title="车辆自编号" />
                        <vxe-table-column field="carModelName" title="车型" />
                        <vxe-table-column
                            field="manufacturer"
                            title="生产厂商"
                        />
                        <vxe-table-column
                            field="purchaseTime"
                            title="购入日期"
                        />
                        <vxe-table-column
                            field="companyName"
                            title="所属公司"
                        />
                        <!-- <vxe-table-column
                            field="carLine"
                            title="车辆行驶线路"
                        /> -->
                        <vxe-table-column
                            title="已绑定驾驶员"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                <div v-if="row.realName">
                                    {{ row.realName }}
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            field="drivingMileage"
                            title="行驶里程"
                        />
                        <vxe-table-column
                            field="lastTimeAsTime"
                            title="上次年检时间"
                        />
                        <vxe-table-column
                            field="nextTimeAsTime"
                            title="下次年检时间"
                        />
                        <vxe-table-column
                            sortable
                            field="daysRemain"
                            title="距离下次年检还剩"
                            width="180"
                        >
                            <template v-slot="{ row }">
                                <div v-if="typeof row.daysRemain == 'number'">
                                    <div
                                        :style="
                                            row.daysRemain < 0
                                                ? `color: #DB1A1A`
                                                : `color: #33CCB0`
                                        "
                                    >
                                        {{ row.daysRemain }}天
                                    </div>
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column field="statu" title="状态">
                            <template v-slot="{ row }">
                                <span v-if="row.statu === 0">在用</span>
                                <span v-else-if="row.statu === 1">停用</span>
                                <span v-else>报废</span>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column field="" title="操作">
                            <template v-slot="{ row, rowIndex }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:car:edit'
                                                )
                                            "
                                            icon="el-icon-edit"
                                            @click.native="
                                                openDialog(
                                                    {
                                                        title: '修改车辆',
                                                        addOr: 'upd',
                                                    },
                                                    row
                                                )
                                            "
                                            >修改车辆</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:car:user'
                                                ) && isBindUserAuth
                                            "
                                            icon="el-icon-user"
                                            @click.native="
                                                openUser(
                                                    {
                                                        title: '绑定驾驶员',
                                                        addOr: 'add',
                                                    },
                                                    row
                                                )
                                            "
                                            >绑定驾驶员</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:car:view'
                                                )
                                            "
                                            icon="el-icon-zoom-in"
                                            @click.native="
                                                op.row = row;
                                                op.id = row.id;
                                                showLookDetails = true;
                                            "
                                            >查看详情</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:car:delete'
                                                )
                                            "
                                            icon="el-icon-delete"
                                            @click.native="
                                                remove({ del: 'single' }, row)
                                            "
                                            >删除</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                    <div style="height: 20px" />
                    <el-pagination
                        background
                        :current-page="table.currentPage"
                        :page-sizes="[5, 10, 15, 20, 50, 100]"
                        :page-size="table.pageSize"
                        layout="total, ->, sizes, prev, pager, next, jumper"
                        :total="table.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </el-row>
            </el-card>
        </div>
        <LookDetails
            :op="op"
            :id="op.id"
            :companyId="companyId"
            v-else
            class="dashboard-container"
            @close="showLookDetails = false"
        >
        </LookDetails>
    </div>
</template>

<script>
import LookDetails from "./components/LookDetails.vue";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { carManage, permissions, company } from "@/api";
import Operate from "./Operate";
import BatchExport from "./BatchExport";
import User from "@/pages/organize/manageCar/User";
import InspectDetail from "@/pages/organize/manageCar/inspectDetail";
export default {
    name: "Index",
    components: {
        User,
        Operate,
        BatchExport,
        InspectDetail,
        LookDetails,
    },
    data() {
        return {
            showLookDetails: false,

            company: [],
            companyId: "",
            loading: false,
            condition: {
                carNum: "",
                date: "",
                search: "",
                lineId: "",
                sDate: "",
                eDate: "",
                carNo: "",
                direction: "ASC",
                carModels: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 20,
                sortName: "",
                sortBy: "",
            },
            op: {
                id: "",
                row: null,
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                refresh2: false, // 每次刷新DOM
                refresh3: false, // 每次刷新DOM
                refreshUser: false, // 每次刷新DOM
                refreshIns: false, // 每次刷新DOM
                show: false,
                show2: false,
                show3: false,
                showUser: false,
                showIns: false,
            },
            lineList: [],
        };
    },
    created() {
        this.getCompany();
    },
    computed: {
        isBindUserAuth() {
            let flag = false;
            this.company.forEach((item) => {
                if (item.id == this.companyId && item.industry == "0") {
                    flag = true;
                    return;
                }
            });

            return flag;
        },
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openUser(info, row) {
            this.dialog.refreshUser = true;
            this.dialog.showUser = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        // 打开批量导入
        openExport() {
            this.dialog.refresh2 = true;
            this.dialog.show2 = true;
        },
        openView(info, row) {
            this.dialog.refresh3 = true;
            this.dialog.show3 = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openIns(info, row) {
            this.dialog.refreshIns = true;
            this.dialog.showIns = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        changeCondition(v) {
            this.table.currentPage = 1;
            this.$refs.table.clearCheckboxRow();
            this.getList();
            if (v == 1) {
                this.lineList = [];
                this.condition.lineId = "";
                this.getLine();
            }
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    currentPage: 1,
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                if (res.data.content && res.data.content.length > 0) {
                    this.company = res.data.content;
                    this.companyId = res.data.content[0].id;
                    this.getLine();
                    this.getList();
                }
            });
        },
        getLine() {
            this.$axiosReq(
                "/car/server/escort/web/line/companyId",
                null,
                {
                    companyId: this.companyId,
                },
                "get"
            ).then((res) => {
                if (res.data && res.data.length > 0) {
                    this.lineList = res.data;
                }
            });
        },

        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.condition.direction == order.toUpperCase()) {
                return null;
            }
            this.condition.direction = order == "asc" ? "ASC" : "DESC";
            this.getList();
        },

        getList() {
            this.loading = true;
            this.$axiosReq(
                carManage,
                null,
                {
                    companyId: this.companyId,
                    carNumSearch: this.condition.carNum,
                    carNo: this.condition.carNo,
                    lineId: this.condition.lineId,
                    startTime: this.condition.date
                        ? this.condition.date[0]
                        : null,
                    endTime: this.condition.date
                        ? this.condition.date[1]
                        : null,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                    direction: this.condition.direction, //DESC, ASC
                    property: "nextTimeAsTime", //排序字段，默认创建时间
                    carModels: this.condition.carModels,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        removeAll() {
            const id = [];
            const arr = this.$refs.table.getCheckboxRecords();
            for (const i of arr) {
                id.push(i.id);
            }
            if (!id || id.length < 1) {
                this.$message.info("请选择需要删除的对象！");
                return false;
            }
            this.$confirm("确认删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        "/car/server/escort/web/carManage",
                        id,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        remove(info, row) {
            this.$confirm("确认删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        carManage + "/" + row.id,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        changeStatus(v, id) {
            changeCarStatus(
                {
                    carId: id,
                    status: v,
                },
                null,
                "put"
            ).then((res) => {
                this.$message.success(res.data.msg);
                this.getList();
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.table.list = [];
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
            this.dialog.refresh2 = false;
            this.dialog.refresh3 = false;
            this.dialog.refreshUser = false;
            this.dialog.refreshIns = false;
            this.getList();
        },
        selectAll(e) {
            console.log(JSON.stringify(e));
        },
        selectBox() {
            console.log(JSON.stringify(event));
        },
    },
};
</script>

<style scoped></style>
