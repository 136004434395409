<template>
    <div class="active-4-box">
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">总体情况</div>
        </div>
        <div class="active-4-info-box">
            <el-descriptions title="" :column="4">
                <el-descriptions-item label="发生事故次数"
                    >{{ info.allNum || "--" }}次</el-descriptions-item
                >
                <el-descriptions-item label="直接经济损失"
                    >{{ info.allCoasts || "--" }}元</el-descriptions-item
                >
            </el-descriptions>
        </div>
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">车辆事故记录</div>
        </div>
        <div class="active-4-table-box">
            <vxe-table
                ref="vxeTable"
                border
                resizable
                show-overflow
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyleFn"
                align="center"
                class="vxe-table-element"
                height="300"
                :data="records"
                :sort-config="{ sortMethod: customSortMethod }"
            >
                <vxe-table-column title="事故发生日期">
                    <template v-slot="{ row }">
                        {{ row.accidentTime }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="事故发生地点"
                    show-header-overflow
                    show-overflow="title"
                    show-footer-overflow
                >
                    <template v-slot="{ row }">
                        {{ row.accidentLocation }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="事故性质"
                    show-header-overflow
                    show-overflow="title"
                    show-footer-overflow
                >
                    <template v-slot="{ row }">
                        {{ row.accidentNature }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="事故原因"
                    show-header-overflow
                    show-overflow="title"
                    show-footer-overflow
                >
                    <template v-slot="{ row }">
                        {{ row.accidentAccident }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="车辆损坏情况"
                    show-header-overflow
                    show-overflow="title"
                    show-footer-overflow
                >
                    <template v-slot="{ row }">
                        {{ row.damage }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="直接经济损失(元)">
                    <template v-slot="{ row }">
                        ￥ {{ row.directEconomicLoss }}
                    </template>
                </vxe-table-column>
            </vxe-table>
            <div style="height: 20px"></div>
            <el-pagination
                background
                :current-page.sync="ajaxParam.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size.sync="ajaxParam.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="
                    () => {
                        queryList();
                    }
                "
                @current-change="
                    () => {
                        queryList();
                    }
                "
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: "",
        },
        op: {
            type: Object,
            default: () => {
                return {
                    row: null,
                    id: "",
                };
            },
        },
        companyId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: "",
                // direction: "ASC", //排序方式，默认降序 DESC, ASC
                // property: "nextReplaceTime", // 排序字段，默认创建时间
            },
            info: {
                allCoasts: "",
                allNum: "",
            },
            records: [],
            total: 0,
        };
    },
    created() {
        // this.queryList();
    },
    methods: {
        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.ajaxParam.direction == order.toUpperCase()) {
                return null;
            }
            this.ajaxParam.direction = order == "asc" ? "ASC" : "DESC";
            this.queryList();
        },
        async queryList(startDate = "", endDate = "") {
            // const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            // delete ajaxParam.companyId;

            {
                const ajaxParam = {
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/CarAccidentRecord/statistic/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );

                if (code === 200) {
                    data &&
                        Object.keys(this.info).forEach((key) => {
                            this.info[key] = data[key];
                        });
                }
            }

            {
                const ajaxParam = {
                    currentPage: this.ajaxParam.currentPage,
                    pageSize: this.ajaxParam.pageSize,
                    companyId: this.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    // direction: "ASC", //排序方式，默认降序 DESC, ASC
                    // property: "nextReplaceTime", // 排序字段，默认创建时间
                };
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/CarAccidentRecord/statistic/list/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    this.records = data.content;
                    this.total = ~~data.totalElements;
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active-4-box {
    border: 1px solid #e9e9e9;
    padding: 32px;
    border-radius: 6px;
    .title-box {
        // height: 50px;
        display: flex;
        align-items: center;

        .title-icon {
            width: 5px;
            height: 16px;
            background: rgb(72, 152, 226);
            margin-right: 10px;
        }
    }

    .active-4-info-box {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 16px;
        .info-item {
            width: 300px;
        }
    }
    .active-4-table-box {
        padding: 16px 0;
    }
}
</style>
